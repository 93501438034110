import { storyData } from './climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import WedoIco3 from '@/assets/img/nodiscrimination/childprotection_obj1_2@2x.png';
import WedoIco2 from '@/assets/img/nodiscrimination/childprotection_obj2020_3@2x.png';
import WedoIco4 from '@/assets/img/nodiscrimination/childprotection_obj2_4@2x.png';
import WedoIco5 from '@/assets/img/nodiscrimination/education_obj2020_1@2x.png';
import WedoIco1 from '@/assets/img/nodiscrimination/fairchance_obj2020_1@2x.png';
import CardImg1 from '@/assets/img/tmp/building-future/gender_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/tmp/building-future/gender_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/tmp/building-future/gender_card_img3@2x.jpg';
import CardImg4 from '@/assets/img/tmp/building-future/gender_card_img4@2x.jpg';
import CardImg5 from '@/assets/img/tmp/building-future/gender_card_img5@2x.jpg';
import AccordionComponent, { AccordionItem } from '@/components//Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTop,
  SectionWedo,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { graphql, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `평등한 보건·영양·식수와 위생 관리`,
    dec: `많은 지역사회에서 여전히 아들에 대한 선호가 높은 것을 볼 수 있습니다. 이에 대한 영향으로 여자 어린이는 적절한 시기에 건강 관리를 하지 못하거나 적절한 영양소를 섭취하지 못하는 경우가 발생합니다. 유니세프는 모든 어린이가 성별과 무관하게 건강을 관리할 수 있도록 지역사회 관습을 개선하기 위해 교육하고 홍보합니다. 또한 식수와 위생 시스템 설계 및 전달 과정에서 여성의 참여를 늘리고 인력 전문화를 통해 경제적인 힘을 실어주기도 합니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `평등한 교육과 교육시스템`,
    dec: `성별에 따른 관습으로 인해 배우지 못하는 어린이가 많습니다. 배움 대신 여자 어린이에게는 집안 일이나 동생을 돌보는 일을, 남자 어린이에게는 생계를 떠맡기기도 합니다. 특히 여자 어린이의 경우 여자라는 이유로 자신의 잠재력과 무관하게 학업을 중간에 포기해야 하는 경우도 생깁니다. 그래서 여자 어린이가 교육에서 취업에 이르는 경로를 지원하기 위해서 학습 기회를 제공하는 것 이상의 노력이 필요합니다. 여자 어린이의 개발 상황에 맞는 시간과 장소에서 학습 환경을 제공하고 장애가 있는 여자 어린이를 위한 보조 기술과 디지털 플랫폼 활용, 직업 훈련 프로그램에 대해서도 연구합니다. `,
    img: CardImg2,
  },
  {
    id: 3,
    title: `성별 기반 폭력 대응`,
    dec: `성별 기반 폭력 대응은 조혼, 할례 등 문화에 대한 대응과 안전한 장소 제공, 생리 용품 지원, HIV/AIDS 치료 제공 등 구호품 지원, 심리 사회적 지원과 연결되어 있습니다. 유니세프는 특히 애플리케이션이나 가상 공간을 활용한 신고 체계가 활발히 작동할 수 있도록 개발하고 투자하며, 정부 관료를 비롯한 각계각층의 인식 증진 프로그램을 지원합니다. `,
    img: CardImg3,
  },
  {
    id: 4,
    title: `올바른 성인식 제고`,
    dec: `차별적인 성규범은 양성평등을 가로막는 가장 큰 장벽입니다. 출생 시부터 청소년기를 지나 성인이 될 때까지 고착된 행동과 사고방식이 불평등을 부채질합니다. 유니세프는 올바른 성인식 제고를 위해 지역사회와 함께 사회적 행동 변화 커뮤니케이션을 진행합니다. 지역사회 내에 영향력을 행사하는 주요 인물과의 커뮤니케이션을 비롯해, 라디오나 TV와 같은 대중 매체나 U-Report나 소셜 미디어 등 디지털 매체를 이용해 긍정적인 성 사회화를 위해 지원합니다.`,
    img: CardImg4,
  },
  {
    id: 5,
    title: `모성 관리`,
    dec: `많은 수의 여성이 임신과 출산과정에서 발생하는 합병증으로 목숨을 잃습니다. 안전한 물과 위생, 의료 시설이 없는 환경에서 수백만 명을 출산합니다. 유니세프는 숙련된 의료진과 산파가 산모와 아기를 효과적으로 지원하고 건강하게 출산하고 관리할 수 있도록 지원합니다.`,
    img: CardImg5,
  },
];

const wedo = [
  {
    id: 1,
    tit: `2020년 주요 성과`,
    col: `col_4`,
    child: [
      {
        id: 1,
        img: WedoIco1,
        desc: [
          {
            dec: `<strong>84개국</strong>에서 성범죄 <strong>약 1,780만 건</strong> 중재, 대응 및 예방`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `<strong>91개국</strong>과 협력하여 안전하고 접근가능한 성폭력 신고 프로그램 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco3,
        desc: [
          {
            dec: `UNFPA와 함께 <strong>45개국</strong> 여자 어린이 및 청소년 <strong>600만 명</strong>에게 조혼 방지 캠페인 실시`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    tit: `2021년 주요 성과`,
    col: `col_4`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `여자 어린이 <strong>760만 명</strong>을 조혼으로부터 보호`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco4,
        desc: [
          {
            dec: `할례 만연 지역(소말리아, 수단, 나이지리아 등)에서 할례 근절 프로그램 진행 (2011년 41%에서 2021년 <strong>34%</strong>로 할례 비율 감소)`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco5,
        desc: [
          {
            dec: `학교에 가지 못하는 여자 어린이를 위해 긴급 교육 프로그램 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    tit: `2022년 주요 성과`,
    col: `col_4`,
    child: [
      {
        id: 1,
        img: WedoIco5,
        desc: [
          {
            dec: `<strong>37개</strong> 정부에게 성평등 관련 사회보호제도를 수립하도록 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco4,
        desc: [
          {
            dec: `긴급구호 상황에서 여성과 어린이 <strong>880만 명</strong>을 성범죄로부터 보호`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco3,
        desc: [
          {
            dec: `<strong>3,110만 명</strong>과 성관련 악폐습, 성폭력, 성착취에 대응하는 옹호 활동 진행`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    tit: `2023년 주요 성과`,
    col: `col_4`,
    child: [
      {
        id: 1,
        img: WedoIco5,
        desc: [
          {
            dec: `여자 어린이 및 여성 <strong>790만 명</strong>에게 생리위생 프로그램 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco4,
        desc: [
          {
            dec: `조혼으로부터 여자 어린이 <strong>1,100만 명</strong> 예방 및 보호`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco3,
        desc: [
          {
            dec: `<strong>20개국</strong> 어린이와 여성 <strong>약 60만 명</strong>에게 할례 근절 프로그램 제공`,
          },
        ],
      },
    ],
  },
];

const NavigationButton = styled.div``;

const GenderEquality: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST08'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A002'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="성 평등"
      description="for every child, a fair chance"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 성별과 관계없이 공평하게{` `}
                  <br className="small-hide" />
                  권리를 누릴 수 있어야 합니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                모든 어린이가 공평하게 권리를 실현하기 위해서는 성 평등이 반드시
                이뤄져야 합니다. 성 평등은 보건, 교육, 어린이 보호, 어린이
                발달을 포함한 다른 주요 부문과도 연결되어 있습니다. 성 평등을
                이루면 성별에 무관하게 모든 개인의 잠재력을 발휘할 수 있는
                안정적이고 탄력적인 사회를 구축할 수 있습니다. 유니세프는 유해한
                성적 관습을 개선하기 위해 교육하며, 어린이의 올바른 성인식
                제고를 위해 지원합니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="tHtW8fWVTfo" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                모든 어린이의 성 평등을 위해, <br /> 전 세계 파트너와 함께
                유니세프가 한 일
              </Tit>
            </h2>
          </SectionHeader>

          <AccordionComponent initialKey={wedo[wedo.length - 1].id}>
            {wedo.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    {row.tit}
                  </NavigationButton>
                )}
              >
                <div className="accordion-body">
                  <ProcessList
                    itemData={row.child}
                    type={row.col ? row.col : ``}
                  />
                </div>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </SectionWedo>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A002" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default GenderEquality;
